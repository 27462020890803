<template>
  <div class="platforms-container" :class="stack ? 'stacked':''" v-if="primary && secondary">
    <div class="platform">
      <Button
        class="special"
        :label="`Download for ${primary.name}`"
        :icon="primary.icon"
        @click="download(primary.link)"
      />
      <span v-if="showDetails">
        {{primary.file}}
        ({{primary.megabytes}}mb)
      </span>
    </div>
    <div class="platform" v-if="showSecondary">
      <Button
          class="secondary"
          :label="`Download for ${secondary.name}`"
          :icon="secondary.icon"
          @click="download(secondary.link)"
      />
      <span v-if="showDetails">
        {{secondary.file}}
        ({{secondary.megabytes}}mb)
      </span>
    </div>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import Button from "@/components/atoms/Button.vue";

  export default Vue.extend({
    components: {
      Button
    },
    props: {
      showSecondary: {
        type: Boolean,
        default: true
      },
      autoDownload: {
        type: Boolean,
        default: false
      },
      showDetails: {
        type: Boolean,
        default: true
      },
      stack: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      primary(){
        return this.$store.getters['release/primary']
      },
      secondary(){
        return this.$store.getters['release/secondary']
      }
    },
    watch: {
      primary(){
        if(this.autoDownload){
          this.download(this.primary.link);
        }
      }
    },
    mounted(){
      this.$store.dispatch('release/load')
    },
    methods: {
      download(link){
        this.$store.dispatch('user/tag', 'Downloaded app');
        window.location.href = link;
      }
    }
  });
</script>
<style scoped lang="less">
  @import "../../less/variables";
  .platforms-container{
    display: flex;
    max-width: 650px;
    margin:0 auto;
    gap: 1em;
    align-items: center;
    justify-content: center;
    &.stacked{
      flex-direction: column;
    }
  }
  .platform{
    display: flex;
    flex-direction: column;
    align-items: center;
    /deep/ button .icon{
      height: 29px;
      width: 25px;
    }
  }

  @media(min-width: @firstbreakpoint){
    .platforms-container{
      grid-template-columns: auto auto;
    }
  }
</style>
