<template>
  <main>
    <div class="app-width">
      <h1>
        Thanks<span v-if="name">, {{name}}</span>!
      </h1>
      <section class="first">
        <h2>1. Download StoryFolder Desktop Beta</h2>
        <AppDownloadLinks />
      </section>
      <section class="last">
        <h2>
          2. Activate with your key
        </h2>
        <div class="key">
          {{key}}
        </div>
      </section>
    </div>
  </main>
</template>
<script lang="ts">
  import Vue from 'vue';
  import AppDownloadLinks from "@/components/molecules/AppDownloadLinks.vue";

  export default Vue.extend({
    components: {
      AppDownloadLinks
    },
    computed: {
      key(){
        return this.$route.params.key
      },
      name(){
        return this.$store.state.user.user.name
      }
    },
    mounted(){
      this.$store.dispatch('user/load');
      if(process.env.NODE_ENV === 'production'){
        window.fbq('track', 'Purchase', {
          currency: "USD",
          value: 9.99
        });
        dataLayer.push({
          event: 'purchase',
          currency: 'USD',
          price: 9.99,
          value: 9.99
        });
        dataLayer.push({
          event: 'conversion',
          currency: 'USD',
          price: 9.99,
          value: 9.99
        });
      }
    }
  });
</script>
<style scoped lang="less">
  @import '../less/variables';
  main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: url(/img/backgrounds/gradient-background.jpg);
    background-size: cover;
    background-position: center center;
    height: 100vh;
    overflow: auto;
  }
  h1{
    margin-bottom: 0.5em;
  }
  .app-width{
    max-width: 675px;
    overflow: auto;
    padding-top: 2em;
    padding-bottom: 2em;
  }
  section{
    text-align: left;
    border:1px solid fade(@white, 10%);
    background: fade(@white, 5%);
    width: 100%;
    box-sizing: border-box;
    border-radius: 1rem;
    padding: 1rem;
    h2{
      margin-top: 0;
    }
    &.first{
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
    }
    &.last{
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  .key{
    background: fade(@white, 90%);
    font-size: 1.25em;
    color: @black;
    padding: 1em 2em;
    font-weight: bold;
    border-radius: 0.5em;
    border:3px dashed fade(@black, 20%);
    text-align: center;
    margin-top: 1.5em;
  }
  /deep/ .platforms-container{
    justify-items: flex-start !important;
    button{
      margin-bottom: 0;
    }
  }

  @media(min-width: @zeroethbreakpoint){
    section{
      padding: 1.5em 2em;
    }
  }

  @media(min-width: @firstbreakpoint){
    section{
      padding: 2.5em 3em;
    }
  }
</style>
